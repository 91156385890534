.heroImg {
  width: 100%;
}

// 
.garden div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;

  a {
    padding: 1em 0.5em;
  }
  img {
    max-height: 50px;
    image-rendering: -webkit-optimize-contrast;
    object-fit: contain;
    // width: 100%;
    // height: 100%;
    padding: 0 0.75em;
  }
}



// custom styling for programs
.mypantryplanner {
  .header {
    background: #201d1e;
  }
}