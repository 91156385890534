.footer-content {
  margin-top: 2em;
  padding: 2em 1em;
}

img.footer-Img {
  width: 90%;
  max-width: 250px;
  margin: 0 auto 2em;
}
p.footer-text {
  width: 85%;
  font-size: 14px; 
  line-height: 1.2;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {

}

@media screen and (max-width: 960px) {
 
}