.moreInfo,.overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .content {
        color:#fff;
        font-size:1.4rem;
        font-weight:700;
        padding:1rem;
        position:relative;
        height:100%;
        display: flex;
        align-items: center;
        .close {
            position: absolute;
            top: 0.35em;
            right: 0.35em;
            color:#fff;
        }
    }
}
.disable-link {
    pointer-events: none;
}