// Deep Link offer
.SingleOffer {
  max-width: 25%;
  margin: 0 auto;
}
a.viewMore {
  margin: 2em 0;
  font-size: 1.5em;
}

// List of offers
.offerContent {
  width: 95%;
  max-width: 1224px;
  margin: 0 auto;
  h2.sectionTitle {
    font-size: 2rem;
    text-align: left;
    padding-bottom: 0.5em;
  }
  .OfferList {
    //display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    // grid-template-rows: auto;
    // grid-gap: 10px;
    // justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    max-width: 1224px;
    margin: 0 auto;
  }
}
// Featured Styles
.FeaturedOfferList {
  padding-bottom: 4em;
  .card {
    border: none;
    max-width: initial;
    margin: 0 auto;
  }
  .logo-img {
    padding-bottom: 16px;
    width: 50%;
    max-height: 200px;
    object-fit: contain;
  }
  .offer {
    width: initial;
    max-width: 960px;
  }
  .featured-block {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    a {
      flex: 50%;
      .offer-img {
        max-width: 450px;
        max-height: 450px;
        object-fit: inherit;
        padding-bottom: 1em;
      }
    }
    div {
      flex: 50%;
      .content {
        .card-title {
          font-size: 50px;
          margin-bottom: 8px;
          -webkit-line-clamp: inherit;
          max-height: inherit;
        }
        .description.card-text {
          font-size: 18px;
          -webkit-line-clamp: inherit;
          max-height: inherit;
          min-height: inherit;
        }
      }
      .offer-btn {
        width: 30%;
      }
      .moreInfo {
        width: 70%;
        .overlay {
          width: 70%;
        }
        .content {
          width: 70%;
          justify-content: space-around;
        }
      }
    }
  }
}
// offer item
.offer {
  position: relative;
  width: 23%;
  margin: 0 1% 2%;
  .content {
    font-size: 0.8em;
    padding: 1rem 1rem 0.5rem;
  }
  .offer-img {
    object-fit: none;
    width: auto;
    padding: 1em 1em 0;
  }
  .card-title,
  .card-text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    padding: 0;
  }
  .card-title {
    -webkit-line-clamp: 2;
    font-weight: 400;
    margin-bottom: 0.5em;
    max-height: 2.4em;
    min-height: 2.4em;
    line-height: 1.1em;
  }
  .description {
    -webkit-line-clamp: 2;
    font-size: 1.1em;
    font-weight: 400;
    margin: 0;
    max-height: 2.3em; //IE11 fix includes font size and line height
    min-height: 2.3em;
    line-height: 1.2em;
  }
  .expiration {
    font-size: 0.9em;
  }
  .offer-btn {
    width: 80%;
    margin: 0 auto 1rem;
    border-radius: 7px;
  }
}
@media screen and(max-width:960px) {
  .offerContent {
    h2.sectionTitle {
      font-size: 1.75rem;
    }
  }
  .offer {
    width: 31%;
    margin: 0 1% 2%;
  }
}
@media screen and (max-width:767px) {
  .offer {
    width: 48%;
  }
}
@media screen and(max-width:500px) {
  .offer {
    width:100%;
    margin: 0 auto 3%;
    .card-title {
      -webkit-line-clamp: initial;
      min-height: initial;
    }
  }
}
// IPAD
@media only screen and (max-width: 960px) and (min-width: 768px) {
  .FeaturedOfferList {
    .featured-block {
      a {
        .offer-img {
          width: 100%;
        }
      }
      div {
        .offer-btn {
          width: 50%;
        }
      }
    }
  }
}
// MOBILE
@media screen and(max-width:767px) {
  .FeaturedOfferList {
    .card {
      max-width: 250px;
    }
    .logo-img {
      width: 100%;
    }
    .banner.card-text {
      width: 100%;
    }
    .featured-block {
      display: block;
      a {
        .offer-img {
          width: 100%;
          padding-bottom: 0;
        }
      }
      div {
        .content {
          .card-title {
            font-size: 30px;
          }
        }
        .offer-btn {
          width: 80%;
        }
        .moreInfo {
          width: 100%;
          .overlay {
            width: 100%;
          }
          .content {
            width: 100%;
          }
        }
      }
    }
  }
}
